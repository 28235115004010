
#footer-nav {
    color: #fff;
    font-size: 24px;
    border-bottom: 1px solid #ffffff;
    text-transform: uppercase;
}

#footer {
    padding-top: 1%;
    /* font-size: ; */
    background: #1b2d51;
    height: fit-content;
    width: 100%;
}

.News {
    color: #ffffff;
}

#cB {
    /* position: relative;
    top: -4rem; */
    top: 4%;
    width: 76%;
    margin-left: 12%;
    /* margin: ; */
    /* padding: 0; */
    color: #ffffff;
    background-color: #0d2047;
    border: 2px solid #ffffff;
}

#footer h5 {
    padding-left: 10px;
    border-bottom: 1px solid #777575;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color: #ffffff;
    /* font-size: ; */
    text-transform: uppercase;
}

#footer a {
    color: #ffffff;
    text-decoration: none !important;
    text-transform: capitalize;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.full_footer {
    padding-top: 2%;
}

#footer a:hover {
    color: #01afeb;
}

#footer .category-list,
.newscate {
    column-count: 2;
    column-gap: 25px;
}

.lorem {
    color: #fff;
    font-size: smaller;
}

.hide-mobile a:hover {
    color: #ffffff;
}

@media only screen and (max-width: 1300px)  and (min-width: 690px) {
    #footer-nav {
        color: #fff;
        font-size: 18px;
        border-bottom: 1px solid #ffffff;
        text-transform: uppercase;
    }

    #footer .category-list,
    .newscate {
        column-count: 2;
        column-gap: 100px;
        font-size: 12px;
    }

    .lorem {
        color: #fff;
        font-size: 10px;
    }
}

#footer ul.newscate {
    list-style-type: none;
}

ul.newscate > li {
    text-indent: -15px;
    width: 7rem;
    height: 2rem;
}

ul.newscate > li:before {
    content: "⁍";
    font-size: 2rem;
    color: #01afeb;
    text-indent: -5px;
    margin-right: 4px;
}

/* usefull link */
#footer ul.useL {
    list-style-type: none;
}

ul.useL > li {
    text-indent: -15px;
    width: 7rem;
    height: 2rem;
    font-size: 14px;
}

ul.useL > li:before {
    content: "⁍";
    font-size: 2rem;
    color: #01afeb;
    text-indent: -5px;
    margin-right: 4px;
}

@media only screen and (max-width: 1300px)  and (min-width: 690px) {
    #footer-nav {
        color: #fff;
        font-size: 16px;
        border-bottom: 1px solid #ffffff;
        text-transform: uppercase;
    }

    #footer ul.useL {
        list-style-type: none;
    }

    ul.useL > li {
        text-indent: -15px;
        width: 7rem;
        height: 2rem;
        font-size: 12px;
    }

    ul.useL > li:before {
        content: "-";
        font-size: 2rem;
        color: #ee2934;
        text-indent: -5px;
        margin-right: 4px;
    }
}

#footer ul li::marker {
    color: #ee2934;
}

#footer .copyright {
    background-color: #0d2047;
}

#e-f {
    height: 60px;
    width: 500px;
    position: relative;
    top: 2rem;
}

#copyright1 {
    height: 2rem;

    /* border-top: #fff;
  border: 2px; */
}

.newsLetter {
    font-size: small;
}

.error-msg-sub {
    padding-top: 0%;
    color: #ee2934;
    /* font-size: ; */
}

@media only screen and (max-width: 1200px) and (min-width: 1100px) {
    #e-f {

        width: 330px;
    }

    .newsLetter {
        font-size: 10px;
    }


}

@media (max-width: 1100px) {
    #cB {

        display: none;
    }
}

#ES {
    position: relative;
    float: right;
    right: 10px;
    top: -22px;
    margin: 5px;
    color: #fff;
    background-color: #ee2934;
    height: auto;
    width: fit-content;
}

#ES:hover {
    background-color: #1a2e51;
}

#footer .copyright p {
    /* margin-left: 1%;
    margin-right: 1%; */
    color: #fff;
    text-transform: none;
    font-size: 15px;
    margin-top: auto;
    cursor: pointer;
}

.full_footer {
    padding-top: 2%;
}

.news_footer {
    /* padding-top: 0%; */
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
}

#social_platforms {
    color: #fff;
    background-color: #1b2d51;
    width: 60%;
}

a#social_platforms:hover {
    color: #ffffff;
    background-color: #01afeb;
}

#contact-us {
    display: none;
}

@media only screen and (max-width: 945px) and (min-width: 700px) {

    #footer-nav {
        color: #fff;
        font-size: 16px;
        border-bottom: 1px solid #ffffff;
        text-transform: uppercase;
    }

    #contact-us {
        display: flex;
        flex-direction: column;
    }

    .hide-mobile {
        display: none;
    }

    .hide-laptop2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 5%;
    }
}

@media (max-width: 700px) {
    #contact-us {
        display: flex;
        flex-direction: column;
    }

    .hide-mobile {
        display: none;
    }

    .hide-laptop2 {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        margin-bottom: 5%;
    }

    #footer {

        background: #1b2d51;
        height: fit-content;
        width: 100%;
    }

    .News {
        color: #ffffff;
        margin-left: 5%;
        margin-top: 5%;
    }

    #cB {
        top: 4%;
        width: 76%;
        margin-left: 12%;

        color: #ffffff;
        background-color: #0d2047;
        border: 2px solid #ffffff;
    }

    #footer h5 {
        padding-left: 10px;
        border-bottom: 1px solid #777575;
        padding-bottom: 6px;
        margin-bottom: 20px;
        color: #ffffff;

        text-transform: uppercase;
    }

    #footer a {
        color: #ffffff;
        text-decoration: none !important;
        text-transform: capitalize;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }

    #footer a:hover {
        color: #01afeb;
    }

    #footer .category-list,
    .newscate {
        column-count: 2;
        column-gap: 35px;
    }

    .lorem {
        color: #fff;
        font-size: 10px;
    }

    #footer ul.newscate {
        list-style-type: none;
    }

    ul.newscate > li {
        text-indent: -15px;
        width: 7rem;
        height: 2rem;
        font-size: 10px;
    }

    ul.newscate > li:before {
        content: "-";
        font-size: 2rem;
        color: #ee2934;
        text-indent: -5px;
        margin-right: 4px;
    }

    #footer ul.useL {
        list-style-type: none;
    }

    ul.useL > li {
        text-indent: -5px;
        width: 7rem;
        height: 2rem;
        font-size: 10px;
    }

    ul.useL > li:before {
        content: "-";
        font-size: 2rem;
        color: #ee2934;
        text-indent: -5px;
        margin-right: 4px;
    }

    #footer ul li::marker {
        color: #ee2934;
    }

    #footer .copyright {
        background-color: #0d2047;
    }

    #e-f {
        height: 90%;
        width: 500px;
    }

    #copyright1 {
        height: 2rem;

    }

    .newsLetter {
        font-size: small;
    }

    .error-msg-sub {
        padding-top: 2%;

    }

    #ES {
        position: relative;
        right: 160px;
        top: 10%;
        color: #fff;
        background-color: #ee2934;
        height: 70%;
        width: 150px;
    }

    #ES:hover {
        background-color: #1a2e51;
    }

    #footer .copyright p {

        color: #fff;
        text-transform: none;
        font-size: 10px;
        margin-top: auto;
        cursor: pointer;
    }

    .full_footer {
        padding-top: 2%;
        position: relative;
    }

    .news_footer {

        margin-top: 3%;
        margin-left: 5%;
        margin-right: 5%;
    }

    #social_platforms {
        color: #fff;
        background-color: #1b2d51;
        width: fit-content;
    }

    a#social_platforms:hover {
        color: #ffffff;
        background-color: #01afeb;
    }

    #cB {
        display: none;
    }

    .Lorem-text {
        display: none;
    }
}

@media (max-width: 570px) {
    #contact-us {
        display: flex;
        flex-direction: row;

    }
}
