@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

.breadcrumb-item+.breadcrumb-item:before {
    content: var(--bs-breadcrumb-divider,"|");
}
#bcb-main{
    /* height: 5rem; */
    background: #f5f5f5;
    padding-top: 1.5rem;
    padding-bottom: 0.1rem;
    font-family: 'Zilla Slab', serif;
    font-size: 20px; 
}
#bcb-home-logo{
    margin-bottom: 10%;
}
#bcb-main [role=button] {
    text-decoration: none;
}
#bcb-link-text{
    text-decoration: none;
    color: #1A2E51;
}
#bcb-item{
    margin-left: 8%;
    text-decoration: none;
}
#bcb-active-item{
    color: #EE2934;
}

#bcb-active-item::before{
    content: var(--bs-breadcrumb-divider,"|");
}
#bcb-third-item::before{
    content: var(--bs-breadcrumb-divider,"|");
}