@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#nv-main{
    display: grid;
    grid-template-columns: 7% 1fr 7%;
    /* width: 100%; */
    height: fit-content;
    font-family: 'Zilla Slab', serif;
    color: #1A2E51;
}

#nv-page{
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2%;
    margin-top: 5rem;
}

#btnnvCatagory{
    color: #ffffff; 
    background:#EE2934;
    width: fit-content;
  }

#nv-right-section{
    display: flex;
    flex-direction: column;
    gap: 1%;
}

#nv-body{
    width: 100%;
max-width: 100%;
}

#nv-title{
    margin-top: 1%;
}


/* ------------------------------------------------------------------- */

#nv-Header{
    display: flex;
    justify-content: space-between;
    height: 3rem;
    background-color: #ffffff ;
    color: #1A2E51;
    border-top: 1px solid #C0C5D0;
    border-bottom: 1px solid #C0C5D0;
    font-family: 'Zilla Slab', serif;
    gap: 32%;
}

#nv-left-head{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    
}

#nv-right-head{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

#nv-Share-Label{
    margin-top: 4%;
}




#btncalendar{
    
    color: #ffffff; 
    background:#EE2934;
    justify-content: center;
}

#head-lables{
    font-size: 16px;
    margin-top: 6%;
}

#head-logos{
    margin-bottom: 3px;
    /* margin-right: 7px; */
}

#line-head{
    height: 35px;
    width: 0.5px;
    color: #909090;
    background-color: #909090;
}

#nv-image{
    margin-top: 1%;
    margin-bottom: 1%;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

#nv-functions{
    border: 1px solid #C0C5D0;
    border-radius: 10px;
    background-color: #f5f5f5;
    height: 6rem;
    display: grid;
    grid-template-columns: 3% 3fr 2fr 3%;
}

#nv-functions-left{
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
}

#nv-function-pair{
    margin-left: 10%;
    margin-top: 1.7rem;
    justify-content: center;
}

#nv-function{
    border: none;
    padding: 0%;
    color: #1A2E51;
    text-decoration: none;
    margin: 1%;

}

#nv-function-text{
    font-size: small;
}

#nv-font-lable{
    margin-top: 0.5rem;
}

#nv-FontRange{
    color: #1A2E51;
    width: 100%;
}

#nv-FontRange-labels{
    /* margin-right: 10%; */
    font-size: small;

}

#nv-functions-right{
    display: flex;
    flex-direction: row;
    justify-content: right;
}

#nv-description{
    margin-top: 3%;
    /* width: 20rem; */
    width: 100%;
    /* max-width: 100%; */
}

.nv-description-14{
    font-size: 14px;
}
.nv-description-16{
    font-size: 16px;
}
.nv-description-18{
    font-size: 18px;
}
.nv-description-20{
    font-size: 20px;
}
.nv-description-22{
    font-size: 22px;
}
.nv-description-24{
    font-size: 24px;
}

#nv-description img{
    max-width: 100% !important;
    height: auto;
}

#nv-description video{    
    max-width: 100%;
    max-height: 40rem;
    height: auto;
}

@media (max-width: 1200px) {
    #nv-main{
        display: grid;
        grid-template-columns: 7% 1fr 7%;
        /* width: 100%; */
        height: fit-content;
        font-family: 'Zilla Slab', serif;
        color: #1A2E51;
    }
    
    #nv-page{
        grid-column: 2/3;
        display: grid;
        grid-template-columns:1fr;
        gap: 2%;
        margin-top: 5rem;
    }
    
    #btnnvCatagory{
        color: #ffffff; 
        background:#EE2934;
        width: fit-content;
      }
    
    #nv-right-section{

        display: none;
        flex-direction: column;
        gap: 1%;
    }
}

@media (max-width: 800px) {

    #nv-Header{
    
        height: 3rem;
        background-color: #ffffff ;
        color: #1A2E51;
        border-top: 1px solid #C0C5D0;
        border-bottom: 1px solid #C0C5D0;
        font-family: 'Zilla Slab', serif;
        gap: 20%;
    }

    #head-lables{
        font-size: 14px;
        margin-top: 6%;
    }
}

@media (max-width: 660px) {

    #nv-Header{
    
        height: 3rem;
        background-color: #ffffff ;
        color: #1A2E51;
        border-top: 1px solid #C0C5D0;
        border-bottom: 1px solid #C0C5D0;
        font-family: 'Zilla Slab', serif;
        gap: 10%;
    }
}

@media (max-width: 570px) {

        #nv-Header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 6rem;
            background-color: #ffffff ;
            color: #1A2E51;
            border-top: 1px solid #C0C5D0;
            border-bottom: 1px solid #C0C5D0;
            font-family: 'Zilla Slab', serif;
            gap: 0%;
        }
}

@media (max-width: 425px) {
    #nv-FontRange-labels{
        /* margin-right: 10%; */
        font-size: 10px;
    
    }
}


/* ---------------------------------- comments ---------------------------------------------- */


#cs-main{
    display: flex;
    flex-direction: column;
}

#cs-input{
    /* width: 30rem; */
}

#cs-reply-input{
    width: 17rem;
}

#cs-btnsub{
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #1A2E51;
    color: #ffffff;
    width: fit-content;
}

#cv-comment{
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin-bottom: 2%;
}

#cs-profile{
    width: 10%;
    height: 6rem;
    object-fit: cover;
    border-radius: 10px;
}

#cs-profile:before {
    content: "!";
    font-size: 64px;
    padding-left: 43%;
    /* background-image: url(../images/no_image.jpeg); */
    background-color: #f5f5f5;
    display: block;
    width: 200px;
    height: 200px;

  }

#cs-card{
    padding: 1%;
    background-color: #f5f5f5;
    border: 1px solid #C0C5D0;
    width: 87%;
}

#cdbtnReport{
    text-decoration: none;
    color: #EE2934;
    position: absolute;
    right: 1%;
    top: 2%;
}

#cs-card-text{
   
}

#csbtnLike{
    width: fit-content;
    border: none;
}

.cdbtnReplay{
    width: fit-content;
    background-color: #EE2934;
    color: #ffffff;
    border: none;
    position: absolute;
    right: 1%;
    bottom: 6%;
}


#cv-replay-propover{
    width: 17rem;
    padding: 0%;
}

#cv-replay-form{
    width: 17rem;
}

#cdbtnsubReplay{
    /* width: fit-content; */
    width: 100%;
    background-color: #1A2E51;
    color: #ffffff;
    border: none;
    /* padding: 2%; */
    
}

#cdbtnsubReplay:hover{
    /* width: fit-content; */
    width: 100%;
    background-color: #EE2934;
    color: #ffffff;
    border: none;
    /* padding: 2%; */
    
}

#cs-like-dis{
    display: flex;
    flex-direction: row;
}

/* ---------------------------------- replay comments ---------------------------------------------- */

#cv-Rcomment{
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin-bottom: 2%;
    justify-content: right;
}

#cs-Rcard{
    padding: 1%;
    background-color: #f5f5f5;
    border: 1px solid #C0C5D0;
    width: 74%;
}

