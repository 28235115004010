@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');


#RNews-main{
  font-family: 'Zilla Slab', serif;
    border: 1px solid #C0C5D0;
    background-color: #F5F5F5;
    border-radius: 5px;
    height: fit-content;
    }

    #RNews-cat-nav{
        border: 2px solid #EE2934;
        border-radius: 5px;
        color: #ffffff;
        background-color: #EE2934;
        /* padding-top: 2%; */
        justify-content: center;
        height: 3rem;
      }
    
    #RNews-card{
      width: 96%;
      height: 6rem;
      margin-left: 3%;
      margin-right: 3%;
      margin-top: 3%;
      margin-bottom: 1%;
      background-color: #F5F5F5;
      overflow: hidden;
      border: none;
      display: flex;
      flex-direction: row;
    }

    #RNews-card:hover {
      border: 1px solid #C0C5D0;
    }
    
    #RNews-image{
     object-fit: cover;
     height: 6rem;
     width: 30%;
     min-width: 30%;
     max-width: 30%;
     border-radius: 5px;
    }
    
    #RNews-card-body{
      width: 70%;
      /* padding: 5%; */
      padding-left: 2%;
    }

    #btnRNewsCatagory{
        color: #ffffff; 
        background:#EE2934;
        width: fit-content;
        margin-top: 3%;
        margin-left: 3%;
      }
    
    #RNews-card-text{
      margin: 3%;
    }