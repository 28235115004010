@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");

#cv-main {
  font-family: "Zilla Slab", serif;
  display: grid;
  grid-template-columns: 6% 1fr 6%;
}

#cv-content {
  display: grid;
  grid-column: 2/3;
}

#cv-card {
  width: 100%;
  height: 27rem;
  border: none;
  margin-top: 1%;
}

#cv-card:hover {
  border: 1px solid #c0c5d0;
}

#cv-card-image {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

#cv-card-body {
  /* margin-top: 20%; */
  padding: 1%;
  height: 40%;
}

#cv-btnCatagory {
  margin-top: 3%;
  color: #ffffff;
  background: #ee2934;
  width: fit-content;
}

#cv-card-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 2%;
}

#btncvRead {
  color: #ee2934;
  background: #d1d1d1;
  border: none;
}

#cv-card-date {
  font-size: large;
}

#cv-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

@media (max-width: 2000px) {
  #getsubcatwrapper {
    margin: 0 112px;
    overflow-y: scroll;
  }
}
@media (max-width: 1750px) {
  #getsubcatwrapper {
    margin: 0 112px;
    overflow-y: scroll;
  }
}
@media (max-width: 1500px) {
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}

@media (max-width: 1370px) {
  #cv-card-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }
  #cv-card-date {
    font-size: 14px;
  }
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}

@media (max-width: 1250px) {
  #cv-card-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }
  #cv-card-date {
    font-size: 14px;
  }
  #getsubcatwrapper {
    margin: 0 112px;
    overflow-y: scroll;
  }
}

@media (max-width: 1090px) {
  #cv-card-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }
  #cv-card-date {
    font-size: 12px;
  }
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}

@media (max-width: 850px) {
  #cv-btnCatagory {
    margin-top: 3%;
    color: #ffffff;
    background: #ee2934;
    padding: 1%;
    width: fit-content;
  }
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}

@media (max-width: 770px) {
  #cv-card {
    width: 100%;
    height: 10rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
  }
  .subcategoryitem {
    font-size: 12px !important;
  }
  .getcatul {
    justify-content: space-between !important;
  }

  #cv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #cv-card-body {
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
  }
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}

@media (max-width: 425px) {
  #cv-card {
    width: 100%;
    height: 8rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
  }

  #cv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #cv-card-body {
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
  }

  #cv-btnCatagory {
    margin-top: 3%;
    color: #ffffff;
    background: #ee2934;
    padding: 1%;
    font-size: 10px;
    width: fit-content;
  }

  #cv-card-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }

  #cv-card-date {
    font-size: 10px;
  }
  #getsubcatwrapper {
    margin: 0 44px;
    overflow-y: scroll;
  }
}
@media (max-width: 375px) {
  #getsubcatwrapper {
    margin: 0 24px;
    overflow-y: scroll;
  }
}
.getcatul {
  justify-content: flex-start;
}
.subcategoryitem {
  font-size: 16px;
  padding: 9px;
  border-radius: 10px;
}
.subcategoryitem:hover {
  background-color: #01afeb !important;
  color: white;
}

/* -------------------------responsive-------------------------- */
#getsubcatwrapper::-webkit-scrollbar {
  display: none;
}
