@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#BNV-main{
    font-family: 'Zilla Slab', serif;
    display: grid;
    grid-template-columns: 6% 1fr 6%;
    margin-top: 2%;
}

#BNV-content{
    display: grid;
    grid-column: 2/3;
}

#BNV-card{
    width: 100%;
    height: 25rem;
    border: none;
    margin-top: 1%;
    background-color: #ffffff;
}

#BNV-card:hover{
    border: 1px solid #C0C5D0;
}

#BNV-card-image{
    width: 100%;
    height: 70%;
    object-fit: cover;
}

#BNV-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    height: 30%;
}

#BNV-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    width: fit-content;
}

#BNV-card-title{
    margin-top: 2%;
}

#btnBNVRead{
    color:#EE2934; 
    background:#d1d1d1;
    border: none;
}

#BNV-card-date{
    font-size: large;
}

#BNV-logoCalendar{
    margin-bottom: 0.6%;
    margin-right: 1%;
}
#BNV-btnVideo {
    float: inline-end;
    color: #ffffff;
    background: #fff;
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    right: 5%;
    top: 5%;
  }

  #BNV-btnVideo:hover {
    /* transform: rotate(360deg); */
    box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
      0 0 1px 16px rgba(255, 255, 255, 0.1);
  }

  #BNV-btnVideo-logo {
    margin-left: 10%;
  }

  @media (max-width: 1370px) {

    #BNV-card-title{
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #BNV-card-date{
        font-size: 14px;
    }        
}

@media (max-width: 1250px) {

    #BNV-card-title{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #BNV-card-date{
        font-size: 14px;
    }        
}

@media (max-width: 1090px) {
    #BNV-card-title{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #BNV-card-date{
        font-size: 12px;
    }   
}

@media (max-width: 850px) {
    #BNV-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    padding: 1%;
    width: fit-content;
}
}

@media (max-width: 770px) {
    #BNV-card{
    width: 100%;
    height: 10rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
}

#BNV-card-image{
    max-width: 30%;
    height: 100%;
    object-fit: cover;
}

#BNV-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
}
}

@media (max-width: 425px) {
    #BNV-card{
    width: 100%;
    height: 8rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
}

#BNV-card-image{
    max-width: 30%;
    height: 100%;
    object-fit: cover;
}

#BNV-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
}

#BNV-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    padding: 1%;
    font-size: 10px;
    width: fit-content;
}

#BNV-card-title{
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }

#BNV-card-date{
    font-size: 10px;
    } 
}