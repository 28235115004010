
@media screen and (min-width: 700px) {
  #card-noti {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  #main-Noticard {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  #flexCheckChecked {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    margin-top: 15px;
    background-color: #ee2934;
  }
  #btnNotification1 {
    width: 10%;
    color: #ffffff;
    background-color: #ee2934;
  }
  #btnNotification1:hover {
    background-color: #1a2e51;
  }
  #btnNotification11 {
    width: 10%;
    color: #000000;
    background-color: #edf2f8;
  }
  #btnNotification11:hover {
    color: #ffffff;
    background-color: #1a2e51;
  }
  #btnNewsnoti {
    width: 10%;
    color: #000000;
    background-color: #edf2f8;
  }
  #btnNewsnoti:hover {
    color: #ffffff;
    background-color: #1a2e51;
  }
  #btnNewsnoti1 {
    width: 10%;
    color: #ffffff;
    background-color: #ee2934;
  }
  #btnNewsnoti1:hover {
    color: #ffffff;
    background-color: #1a2e51;
  }
  #noti_img {
    width: 15%;
    height: 8rem;
    border-radius: 3%;
  }
  #noti_profile {
    object-fit: cover;
    width: 13%;
    height: 8rem;
    border-radius: 3%;
  }
  #btntrash {
    color: #000000;
    border: 1px solid #ee2934;
    background-color: rgba(230, 113, 92, 0.345);
    width: 8rem;
    height: 2.1rem;
    padding: 1%;
  }
  #btntrash:hover {
    border: 1px solid #1a2e51;
    background-color: #1a2e51;
    color: #ffffff;
  }

  .hide-laptop{
    display: none;
  }
}


@media screen and (max-width: 700px) {

  .hide-mobile{
    display: none;
  }
  #btnNotification1 {
    width: 50%;
    color: #ffffff;
    background-color: #ee2934;
  }
  #btnNotification1:hover {
    background-color: #1a2e51;
  }
  #btnNewsnoti {
    width: 50%;
    color: #000000;
    background-color: #edf2f8;
  }
  #btnNewsnoti:hover {
    color: #ffffff;
    background-color: #1a2e51;
  }
  #card-noti {
    display: grid;
    grid-template-columns:  2fr 2% 4fr 1fr ;
    /* gap: 2%; */
    /* height: 8rem; */
  }
  .Noti-text{
    display: grid;
    grid-column: 3/4;
    font-size: 12px;
    /* width: 110%; */
  }
  #noti_profile {
    display: grid;
    /* grid-column: 2/3; */
    object-fit: cover;
    width: 100%;
    height: 6rem;
    border-radius: 3%;
  }
  #noti_img{
    display: grid;
    /* grid-column: 2/3; */
    object-fit: cover;
    width: 100%;
    /* height: 7rem; */
    border-radius: 3%;
  }
  #btntrash {
    color: #000000;
    border: 1px solid #ee2934;
    background-color: rgba(230, 113, 92, 0.345);
    width: 90%;
    height: 2.1rem;
    margin-top: 2%;
  }
  #btntrash:hover {
    border: 1px solid #1a2e51;
    background-color: #1a2e51;
    color: #ffffff;
  }
  .iconTrash{
    padding-top: 50%;
  }
  .hide-laptop{
    margin-top: -18%;
  }


}
