@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');



  /* font-family: 'Zilla Slab', serif; */

  @import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#bs-no-main{
    text-align: center;
    background-color: #F5F5F5;
}

#bs-no-image{
    width: 15rem;
}
    
#bs-no-title{
  font-size: 40px;
}

#bs-no-text{
    font-size: 20px;
}

  #bs-main{
      font-family: 'Zilla Slab', serif;
      display: grid;
      grid-template-columns: 6% 1fr 6%;
  }
  
  #bs-content{
      display: grid;
      grid-column: 2/3;
  }
  
  #bs-card{
      width: 100%;
      height: 27rem;
      border: none;
      margin-top: 1%;
  }
  
  #bs-card:hover{
      border: 1px solid #C0C5D0;
  }
  
  #bs-card-image{
      width: 100%;
      height: 60%;
      object-fit: cover;
  }
  
  #bs-card-body{
      /* margin-top: 20%; */
      padding: 1%;
      height: 40%;
  }

  #bs-btnBookmark{
    background-color: #1A2E51;
    color: #ffffff;
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    height: 11%;
    width: 3rem;
    position: absolute;
    right: 5%;
    top: 55%;
  }

  #bs-bookmark-logo{
    position: absolute;
    left: 29%;
    top: 30%;
  }
  
  #bs-btnCatagory{
      margin-top: 3%;
      color: #ffffff; 
      background:#EE2934;
      width: fit-content;
  }
  
  #bs-card-title{
      margin-top: 2%;
  }
  
  #btncvRead{
      color:#EE2934; 
      background:#d1d1d1;
      border: none;
  }
  
  #bs-card-date{
      font-size: large;
  }
  
  #bs-logoCalendar{
      margin-bottom: 0.6%;
      margin-right: 1%;
  }

  .bs-ReadMore{
    position: absolute;
    bottom: 3%;
    right: 1%;
    color: #ffffff;
    background-color: #1A2E51;
    /* border: 1px solid #C0C5D0; */

  }

  .bs-ReadMore:hover{
    color: #ffffff;
    background-color: #1A2E51;
    /* border: 1px solid #C0C5D0; */

  }

  @media (max-width: 1450px) {
    #bs-btnBookmark{
        background-color: #1A2E51;
        color: #ffffff;
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        height: 11%;
        width: 3rem;
        position: absolute;
        right: 5%;
        top: 55%;
      }
}

@media (max-width: 766px) {
    #bs-btnBookmark{
        background-color: #1A2E51;
        color: #ffffff;
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        height: 11%;
        width: 3rem;
        position: absolute;
        right: 5%;
        top: 55%;
      }
}

@media (max-width: 450px) {
    #bs-btnBookmark{
        background-color: #1A2E51;
        color: #ffffff;
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        height: 11%;
        width: 3rem;
        position: absolute;
        right: 5%;
        top: 55%;
      }
}