@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#rnv-main{
    font-family: 'Zilla Slab', serif;
    display: grid;
    grid-template-columns: 6% 1fr 6%;
}

#rnv-content{
    display: grid;
    grid-column: 2/3;
}

#rnv-card{
    width: 100%;
    height: 27rem;
    border: none;
    margin-top: 1%;
}

#rnv-card:hover{
    border: 1px solid #C0C5D0;
}

#rnv-card-image{
    width: 100%;
    height: 60%;
    object-fit: cover;
}

#rnv-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    height: 40%;
}

#rnv-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    width: fit-content;
}

#rnv-card-title{
    margin-top: 2%;
}

#btnrnvRead{
    color:#EE2934; 
    background:#d1d1d1;
    border: none;
}

#rnv-card-date{
    font-size: large;
}

#rnv-logoCalendar{
    margin-bottom: 0.6%;
    margin-right: 1%;
}

@media (max-width: 1370px) {

    #rnv-card-title{
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #rnv-card-date{
        font-size: 14px;
    }        
}

@media (max-width: 1250px) {

    #rnv-card-title{
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #rnv-card-date{
        font-size: 14px;
    }        
}

@media (max-width: 1090px) {
    #rnv-card-title{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }
    #rnv-card-date{
        font-size: 12px;
    }   
}

@media (max-width: 850px) {
    #rnv-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    padding: 1%;
    width: fit-content;
}
}

@media (max-width: 770px) {
    #rnv-card{
    width: 100%;
    height: 10rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
}

#rnv-card-image{
    max-width: 30%;
    height: 100%;
    object-fit: cover;
}

#rnv-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
}
}

@media (max-width: 425px) {
    #rnv-card{
    width: 100%;
    height: 8rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
}

#rnv-card-image{
    max-width: 30%;
    height: 100%;
    object-fit: cover;
}

#rnv-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    width: 70%;
    height: 100%;
}

#rnv-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    padding: 1%;
    font-size: 10px;
    width: fit-content;
}

#rnv-card-title{
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 2%;
    }

#rnv-card-date{
    font-size: 10px;
    } 
}