@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");

@media screen and (min-width: 700px) {
  .fonton {
    background-color: #edf2f8;
  }
  #forgot {
    font: "small-caption";
    font-family: "Zilla Slab", serif;
    float: right;
    cursor: pointer;
    padding-top: 2%;
   }
  .bordert {
    border-top: 1px solid #aaa;
  }
  .welcom-back{
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 5%;
    
  }
  #login-social1 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 11.5px;
    color: #ffffff;
    background-color: #3B5998; 

    
  }
  #login-social2 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 11.5px;
    color: #ffffff;
    background-color: #ee2934; 

    
  }
  #login-social3 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 11.5px;
    color: #ffffff;
    background-color: #1b2d51; 

    
  }
  .bordert:after {
    content: "OR";
    position: absolute;
    top: -13px;
    left: 45%;
    background-color: #fff;
    padding: 0px 8px;
  }

  #ModalWrapper {
    min-width: 820px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    background-color: #ee2934;
    border-radius: 20px;
  }

  .Background {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(5px);
  }
  .logo-img-overlay {
    position: absolute;
    top: 3%;
    left: 3%;
  }
  .logo-text {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }
  #logo1 {
    width: 25%;
    height: auto;
  }
  .ModalImg {
    width: 100%;
    height: 42rem;
    border-radius: 21px 0 0 21px;
    opacity: 0.5;
    object-fit: cover;
  }
  .my-2 {
    margin-top: -2.5rem !important;
  }
  .mx-5 {
    margin-right: 37rem !important;
    margin-left: 20rem !important;
  }
  #modal-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
  #mainmodal {
    margin-top: 2%;
    margin-right: 37rem !important;
    margin-left: 20rem !important;
  }
  .ModelHeader {
    position: relative;
    top: 10px;
    left: 20px;
    height: 32px;
    z-index: 10;
  }
  .form {
    position: relative;

    left: 20px;
    height: 32px;
    padding: 20px;
    z-index: 10;
  }

  .footer {
    text-align: center;

    font-size: 120%;
    padding-top: 4%;
  }
  .password-icon {
    position: relative;
    bottom: 43px;
    float: right;
    right: 20px;
    cursor: pointer;
  }
  #loginbutton {
    color: #ffffff;
    background: #1b2d51;
    cursor: pointer;
  }
  #loginbutton:hover{
    background-color: #fff;
    color: #1b2d51;
    border: #1b2d51 solid 3px;
    font-weight: bold;

  }
  .error-msg {
    font-size: smaller;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0.5rem;
  }

  .border-radius-2 div {
    border-radius: 20px;
  }
}

@media screen and (max-width: 700px) {
  #ModalWrapper {
    background-color: #f5f5f5;
    border-radius: 20px;
  }
  #login_img1 {
    display: none;
  }
  #modal-content {
    border-radius: 20px;
  }
  .error-msg {
    font-size: smaller;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0.5rem;
  }
  #login-social1 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 9px;
    color: #ffffff;
    background-color: #3B5998; 
  }
  #login-social2 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 9px;
    color: #ffffff;
    background-color: #ee2934;  
  }
  #login-social3 {
    border: none;
    width: 32%; 
    height: fit-content; 
    font-size: 9px;
    color: #ffffff;
    background-color: #1b2d51; 
  }
    #loginbutton {
    color: #ffffff;
    background: #1b2d51;
  }
  #forgot {
    font: "small-caption";
    font-family: "Zilla Slab", serif;
    float: right;
    right: 0;
    cursor: pointer
   }
  .footer {
    text-align: center;

    font-size: 120%;
    padding-top: 4%;
  }
  .welcom-back{
    margin: 5%;
    
  }
  .bordert {
    border-top: 1px solid #aaa;
  }
  .bordert:after {
    content: "OR";
    position: absolute;
    top: 78%;
    left: 46%;
    background-color: #ffffff;
    padding: 0px 8px;
  }
}
