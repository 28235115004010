@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

#Link-all {
    text-decoration: none;
    color: #1a2e51;
}


/* ----------------------------------- first section -----------------------------------  */

#first-section {
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    background-color: #f5f5f5;
    height: fit-content;
}

#fs-main {
    display: flex;
    flex-direction: column;
}

#body-first-section {
    display: grid;
    grid-template-columns: 8% 1fr 1fr 8%;
    margin-top: 2%;
    margin-bottom: 1%;
    /* height: 45rem; */
}

#Left-first-section {
    position: relative;
    padding: 4%;
    width: 110%;
    display: grid;
    grid-column: 2/3;
    background-color: #ffffff;
    border-radius: 10px;
    /* height: 45rem;   */
}

#Top-Title {
    font-size: 2.7rem;
}

#Top-Description {
    font-size: 24px;

}

#fs-Newscard-image {
    height: 45rem;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

#fs-Radios {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    position: relative;
    /* margin-top: 38%;
    margin-left: 40%; */
    justify-content: center;
    margin-bottom: 1%;
}

#fs-Radiobtn {
    width: 23px;
    height: 23px;
    accent-color: #01afeb;
}
#bcb-active-item
{
    color: #01afeb !important;
}

/* buttons */
#btnCatagory {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-bottom: 5px;
}

#btnReadMore {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-bottom: 5px;
}

#btnReadMore:hover {
    color: #ffffff;
    background: #1a2e51;
}

#btnpaly {
    color: #1b2d51;
    /* background-color: #EDF2F8; */
    /* width: fit-content; */
    margin-left: 2%;
    border: none;
}

#btnpaly-mobile {
    display: none;
}

/* #btnpaly:hover{
/* color: #0F1F40; */
/* } */

@media (max-width: 1350px) {
    #Left-first-section {
        position: relative;
        padding: 4%;
        width: 137%;
        display: grid;
        grid-column: 2/3;
        background-color: #ffffff;
        border-radius: 10px;
        /* height: 45rem;   */
    }

    #Top-Title {
        font-size: 30px;
    }


    #fs-Newscard-image {
        height: 30rem;
        width: 60%;
        object-fit: cover;
        border-radius: 2%;
    }

}

@media (max-width: 1180px) {
    #Left-first-section {
        position: relative;
        padding: 4%;
        width: 137%;
        display: grid;
        grid-column: 2/3;
        background-color: #ffffff;
        border-radius: 10px;
        /* height: 45rem;   */
    }

    #Top-Title {
        font-size: 25px;
    }

    #Top-Description {
        font-size: 20px;

    }

    #fs-Newscard-image {
        height: 30rem;
        width: 60%;
        object-fit: cover;
        border-radius: 10px;
    }

    #fs-Radios {
        margin-top: 2%;
        margin-bottom: 2%;
    }

}

@media (max-width: 1000px) {
    #Left-first-section {
        position: relative;
        padding: 4%;
        width: 137%;
        display: grid;
        grid-column: 2/3;
        background-color: #ffffff;
        border-radius: 10px;
        /* height: 45rem;   */
    }

    #Top-Title {
        font-size: 20px;
    }

    #Top-Description {
        font-size: 18px;

    }

    #fs-Newscard-image {
        height: 20rem;
        width: 60%;
        object-fit: cover;
        border-radius: 10px;
    }

}

@media (max-width: 922px) {

    #body-first-section {
        display: flex;
        flex-direction: column-reverse;
        grid-template-columns: none;
        margin-top: 2%;
        margin-bottom: 1%;
        /* height: 45rem; */
    }

    #Left-first-section {
        position: relative;
        padding: 2%;
        width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        background-color: #ffffff;
        border-radius: 10px;
        /* height: 45rem;   */
    }

    #Top-Title {
        font-size: 20px;
    }

    #Top-Description {
        display: none;

    }

    #fs-Newscard-image {
        height: 20rem;
        width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        object-fit: cover;
        border-radius: 0px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

}

@media (max-width: 700px) {

    #Top-Title {
        font-size: 17px;
    }

    #fs-Newscard-image {
        height: 15rem;
        width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        object-fit: cover;
        border-radius: 0px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    #fs-Radios {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        position: relative;
        /* margin-top: 38%;
        margin-left: 40%; */
        justify-content: center;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    #fs-Radiobtn {
        width: 20px;
        height: 20px;
        accent-color: #01afeb;
    }

    /* buttons */
    #btnCatagory {
        font-size: 12px;
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        margin-bottom: 5px;
    }

    #btnReadMore {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        font-size: 12px;
        margin-bottom: 5px;
    }

    #btnpaly {
        display: none;
    }


    #btnpaly-mobile {
        display: contents;
        color: #1b2d51;
        /* background-color: #EDF2F8; */
        /* width: fit-content; */
        border: none;
    }

    #btnpaly-logo {
        margin-left: 2%;
    }

}

/* ------------------------------------- top-news-section ------------------------------------- */
#tns-main-mobile {
    display: none;
}

#tns-head-main {
    display: grid;
    grid-template-columns: 6% 4fr 1fr 6%;
    margin-top: 3%;
    margin-bottom: 2%;
}

#tns-logo {
    display: grid;
    grid-column: 2/3;
    /* text-align: center; */
    /* max-width: 2000%; */
    position: relative;
}

/* #tns-logo::after {
  content: "";
  display: block;
  width: 1400%;
  height: 1px;
  background: #01afeb;
  position: absolute;
  left: 120%;
  top: 50%;
  z-index: -2;
} */

#tns-Viewmore {
    text-align: end;
    font-family: 'Nunito', sans-serif;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #2b5db3;
    margin-top: 1%;
}

#top-news-section {
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    height: fit-content;
    padding-bottom: 2%;
}

#tns-main {
    display: grid;
    grid-template-columns: 4% 1fr 2fr 1fr 4%;
    justify-content: center;
    /* height: 45rem; */
    gap: 2%;
}

#tns-left-cards {
    display: grid;
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 5%;
    justify-content: right;
}

#tns-right-cards {
    display: grid;
    grid-column: 4/5;
    display: flex;
    flex-direction: column;
    gap: 5%;
}

#top-news-card1 {
    width: 100%;
    height: 46rem;
}

#tns-news-image1 {
    object-fit: cover;
    box-shadow: inset 0px 0px 20px 10px rgba(0, 0, 0, 0.87);
    height: 100%;
    width: 100%;
}

/* .hover02 figure img {
  width: 300px;
  height: auto;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.hover02 figure:hover img {
  width: 350px;
} */

/* #tns-news-image1:hover{
scale: 1.2;
} */

#top-news-card {
    height: 22rem;
    width: 100%;
}

#tns-news-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

#tns-Title {
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 5%;
}

#tns-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#btnTnsCatagory {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-bottom: 2%;
}

@media (max-width: 1350px) {

    #top-news-card1 {
        width: 100%;
        height: 36rem;
    }

    #tns-news-image1 {
        object-fit: cover;
        box-shadow: inset 0px 0px 20px 10px rgba(0, 0, 0, 0.87);
        height: 100%;
        width: 100%;
    }

    #top-news-card {
        height: 17rem;
        width: 100%;
    }

    #tns-news-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

}

@media (max-width: 1080px) {
    #tns-main {
        display: none;
    }

    #top-news-section {
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        height: fit-content;
    }

    #tns-main-mobile {
        display: grid;
        grid-template-columns: 6% 1fr 6%;
        height: fit-content;
    }

    #tns-left-cards {
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: row;
        margin-top: 2%;
        gap: 2%;
    }

    #tns-right-cards {
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: row;
        margin-top: 2%;
        gap: 2%;
    }

    #top-news-card1 {
        display: grid;
        grid-column: 2/3;
        width: 100%;
        height: 15rem;
        border: none;
        display: flex;
        flex-direction: row;
        background-color: #000000;
    }

    #tns-news-image1 {
        object-fit: cover;
        border: none;
        height: 15rem;
        width: 50%;
    }

    #tns-text-card1 {
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
        box-shadow: 12px 0 15px -4px rgba(0, 54, 162, 0), -15px 0 15px -4px rgba(0, 0, 0, 0.97);
        max-width: 50%;
        font-size: 10px;
    }

    #btnTnsCatagory-card1 {
        color: #ffffff;
        background: #01afeb;
        position: relative;
        top: 15%;
        width: fit-content;
        margin-bottom: 5px;
    }

    #tns-Title-card1 {
        /* background-color: rgba(0, 0, 0, 0.5); */
        color: #ffffff;
        font-size: 22px;
        position: relative;
        top: 15%;
    }

    #top-news-card {
        height: 15rem;
        width: 100%;
    }

    #tns-news-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    #tns-text {
        display: flex;
        flex-direction: column;
        padding: 2%;
        /* justify-content: flex-end; */
        font-size: 20px;
        position: absolute;
    }

    #tns-Title {
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        padding: 5%;
        bottom: 3%;

    }

    #btnTnsCatagory {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        padding: 1%;
        margin-bottom: 3%;
    }

    .link-removel {
        text-decoration: none;
    }
}

@media (max-width: 600px) {
    #tns-Title-card1 {
        font-size: 16px;
    }

    #tns-text {
        font-size: 16px;
    }
}

@media (max-width: 425px) {

    #tns-head-main {
        display: grid;
        grid-template-columns: 6% 2fr 1fr 6%;
        margin-top: 3%;
        margin-bottom: 2%;
    }

    #tns-logo {
        display: grid;
        grid-column: 2/3;
        position: relative;
    }

    #tns-Viewmore {
        text-align: end;
        text-decoration: underline;
        margin-top: 1%;
    }

    #tns-left-cards {
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: column;
        margin-top: 2%;
        gap: 2%;
    }

    #tns-right-cards {
        display: none;
        /* display: grid; */
        grid-column: 2/3;
        /* display: flex; */
        flex-direction: column;
        margin-top: 2%;
        gap: 2%;
    }

    #top-news-card1 {
        display: grid;
        grid-column: 2/3;
        width: 100%;
        height: fit-content;
        border: none;
        display: flex;
        flex-direction: column;
        background-color: #1a2e51;
    }

    #tns-news-image1 {
        object-fit: cover;
        border: none;
        height: 10rem;
        width: 100%;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
    }

    #tns-text-card1 {
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
        box-shadow: none;
        padding: 3%;
        max-width: 100%;
        font-size: 10px;
    }

    #btnTnsCatagory-card1 {
        color: #ffffff;
        background: #01afeb;
        /* text-decoration: underline;
        text-decoration-color: #01afeb; */
        font-size: 12px;
        /* padding: 0%; */
        position: relative;
        top: 0%;
        width: fit-content;
        /* margin-bottom: 5px; */
    }

    #tns-Title-card1 {
        background-color: rgba(0, 0, 0, 0);
        color: #ffffff;
        font-size: 14px;
        position: relative;
        top: 12%;
    }

    #top-news-card {
        height: 7rem;
        width: 100%;
    }

    #tns-news-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    #tns-text {
        display: flex;
        flex-direction: column;
        padding: 2%;
        /* justify-content: flex-end; */
        font-size: 14px;
        position: absolute;
    }

    #tns-Title {
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        padding: 2%;
        bottom: 3%;

    }

    #btnTnsCatagory {
        display: none;
        color: #ffffff;
        background: #0000003b;
        text-decoration: underline;
        text-decoration-color: #01afeb;
        width: fit-content;
        padding: 0%;
        margin-bottom: 3%;
    }
}

/* ------------------------------ Breaking News section -------------------------------------*/
#bns-main-mobile {
    display: none;
}

#bns-main {
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    padding-top: 2%;
    background-color: #f5f5f5;
    height: fit-content;
    padding-bottom: 1%;
}

#bns-allCards {
    display: flex;
    flex-direction: row;
    gap: 2%;
}

#bns-rest-cards {
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin-right: 6%;
    height: 27rem;
    /* overflow-y: hidden; */
    overflow-x: scroll;
}

#bns-swiper {
    width: 80%;
    height: 20rem;
}

#bns-main-card {
    height: 25rem;
    width: 21%;
    min-width: 21%;
    margin-left: 6%;
    background: black;
    /* overflow: hidden; */
}

#bns-logo-row {
    display: none;
}

/*
#bns-card-body{
/* height: 40%; */
/* } */
#bns-main-image {
    object-fit: cover;
    opacity: 0.3;
    height: 100%;
    width: 100%;
}

#bns-main-text {
    justify-content: center;
    margin-top: 20%;
    color: #ffffff;
    margin-left: 10%;
    font-size: 40px;
}

#bns-card {
    border-color: #ffffff;
    width: 32%;
    height: 25rem;
    min-width: 32%;
}

#bns-image {
    height: 60%;
    min-height: 60%;
    max-height: 12rem;
    object-fit: cover;
    margin-top: 3%;
    margin-left: 3%;
    width: 94%;
    border-radius: 3%;
}

#bns-card-text {
    margin-top: 2%;
    font-size: 18px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

#btnbnsCatagory {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-bottom: 5px;
}

#btnbnsViewAll {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-bottom: 5px;
}

#bns-np-btns {
    margin-top: 1%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 3%;
}

#btnbnsNP {
    color: #01afeb;
    background: #edf2f8;
    width: fit-content;
    margin-bottom: 5px;
}

@media (max-width: 1350px) {

    #bns-main {
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        padding-top: 2%;
        background-color: #f5f5f5;
        height: fit-content;
        padding-bottom: 1%;
    }

    #bns-rest-cards {
        display: flex;
        flex-direction: row;
        gap: 2%;
        height: 22rem;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    #bns-main-card {
        height: 20rem;
        width: 21%;
        min-width: 21%;
        margin-left: 6%;
        background: black;
        /* overflow: hidden; */
    }

    #bns-card {
        border-color: #ffffff;
        width: 32%;
        height: 20rem;
        min-width: 32%;
    }

    #bns-image {
        height: 60%;
        min-width: 60%;
        max-height: 60%;
        object-fit: cover;
        margin-top: 3%;
        margin-left: 3%;
        width: 94%;
        border-radius: 3%;
    }

    #bns-main-text {
        justify-content: center;
        margin-top: 20%;
        font-size: 36px;
        color: #ffffff;
        margin-left: 10%;
    }

    #bns-card-text {
        margin-top: 2%;
        font-size: 16px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

}

@media (max-width: 1080px) {

    #bns-main {
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        padding-top: 2%;
        background-color: #f5f5f5;
        height: fit-content;
        padding-bottom: 1%;
    }

    #bns-allCards {
        display: flex;
        flex-direction: column;
        gap: 2%;
    }

    #bns-rest-cards {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        margin-left: 6%;
        margin-right: 6%;
        gap: 2%;
        height: 17rem;
        overflow-y: hidden;
        overflow-x: scroll;
        border: 1px solid #1a2e5179;
    }

    #bns-main-card {
        height: 4rem;
        width: 88%;
        min-width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        background: black;
        /* overflow: hidden; */
    }

    #bns-logo-row {
        display: contents;
    }

    #bns-logo-col {
        display: none;
    }

    #bns-card {
        border-color: #ffffff;
        width: 40%;
        height: 15rem;
        min-width: 40%;
    }

    #bns-image {
        height: 60%;
        min-width: 60%;
        max-height: 60%;
        object-fit: cover;
        margin-top: 3%;
        margin-left: 3%;
        width: 94%;
        border-radius: 3%;
    }

    #bns-main-text {
        /* justify-content: center; */
        font-size: 26px;
        margin-top: 0%;
        color: #ffffff;
        margin-left: 1%;
    }

    #bns-card-text {
        margin-top: 2%;
        font-size: 14px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    #btnbnsViewAll {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        margin-bottom: 5px;
        position: absolute;
        right: 2%;
    }
}

@media (max-width: 650px) {

    #bns-rest-cards {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        margin-left: 6%;
        margin-right: 6%;
        gap: 2%;
        height: 17rem;
        overflow-y: hidden;
        overflow-x: scroll;
        border: 1px solid #1a2e5179;
    }

    #bns-main-card {
        height: 4rem;
        width: 88%;
        min-width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        background: black;
    }

    #bns-card {
        border-color: #ffffff;
        width: 45%;
        height: 15rem;
        min-width: 45%;
    }

    #btnbnsCatagory {
        display: none;
    }

}

@media (max-width: 425px) {
    #bns-main-text {
        /* justify-content: center; */
        font-size: 24px;
        margin-top: 0%;
        color: #ffffff;
        margin-left: 1%;
    }

    #bns-card-text {
        margin-top: 2%;
        font-size: 12px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    #bns-rest-cards {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        margin-left: 6%;
        margin-right: 6%;
        gap: 2%;
        height: 15rem;
        overflow-y: hidden;
        overflow-x: scroll;
        border: 1px solid #1a2e5179;
    }

    #bns-card {
        border-color: #ffffff;
        width: 55%;
        height: 14rem;
        min-width: 55%;
    }

    #btnbnsViewAll {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        margin-bottom: 5px;
        position: absolute;
        right: 2%;
        font-size: 12px;
    }
}


/* ------------------------------ Recent news section -------------------------------------*/
#rns-main-mobile {
    display: none;
}

#rns-main {
    display: grid;
    grid-template-columns: 6% 1fr 6%;
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    padding-top: 2%;
    height: fit-content;
}

#rns-head-main {
    display: grid;
    grid-template-columns: 4fr 1fr;

}

#rns-main-logo {
    margin-top: 2%;
    margin-bottom: 3%;
    position: relative;
}


#rns-Viewmore {
    text-align: end;
    font-family: 'Nunito', sans-serif;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #2b5db3;
    margin-top: 8%;
}

#rns-cards-main {
    height: 100%;
    display: grid;
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

#rns-card-row {
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;
}

#rns-card {
    width: 100%;
    height: 528px;
    background-color: #f5f5f5;
}

#rns-image {
    height: 60%;
    min-height: 60%;
    max-height: 60%;
    margin-top: 4%;
    margin-left: 4%;
    /* border-radius: 1%; */
    width: 92%;
    object-fit: cover;
}

#rns-img-overlay {
    height: 60%;
}

#rns-card-body {
    margin-top: 2%;
    padding: 3%;
    height: 17%;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

#rns-card-footer {
    height: 23%;
}

#btnrnsCatagory {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-top: 4%;
    margin-left: 4%;
}

#btnrnsRead {
    width: fit-content;
    height: fit-content;
    position: absolute;
    /* font-size: 25; */
    color: #1a2e51;
    /* margin: 3%; */
    left: 3%;
    bottom: 3%;
    padding: 3%;
    border: 1px solid #1a2e51;
}

#btnrnsRead:hover {
    color: #ffffff;
    background-color: #01afeb;
    border-color: #01afeb;
}

#rns-arrow {
    margin-bottom: 1%;
}

#rns-right-main {
    display: grid;
    grid-column: 4/5;
    display: flex;
    flex-direction: column;
    gap: 1%;
}

#rns-Catagory-main {
    border: 1px solid #c0c5d0;
    border-radius: 1%;
    height: 40rem;
}

#rns-cat-nav {
    border: 2px solid #01afeb;
    border-radius: 3%;
    color: #ffffff;
    background-color: #01afeb;
    /* padding-top: 2%; */
    justify-content: center;
    height: 3rem;
}

#rns-cat-card {
    width: 96%;
    margin-left: 2%;
    margin-top: 3%;
    background: black;
    overflow: hidden;
    border: none;
}

#rns-cat-image {
    object-fit: cover;
    opacity: 0.5;
    height: 5rem;
    width: 100%;
}

#rns-cat-text {
    display: grid;
    grid-template-columns: 2% 7fr 1fr;
    color: #ffffff;
}

#cat-text {
    grid-column: 2/3;
    margin-top: 2%;
}

#btnrns-cat-more {
    grid-column: 3/4;
    color: #ffffff;
    background-color: #00000000;
    border: none;
}

@media (max-width: 1250px) {
    #rns-card-body {
        font-size: 22px;
    }
}

@media (max-width: 850px) {

    #rns-card {
        width: 100%;
        height: 30rem;
        background-color: #f5f5f5;
    }

    #rns-card-body {
        font-size: 18px;
    }
}

@media (max-width: 700px) {

    #rns-card {
        width: 100%;
        height: 24rem;
        background-color: #f5f5f5;
    }

    #rns-card-body {
        font-size: 14px;
    }
}

@media (max-width: 600px) {

    #rns-main {
        display: grid;
        grid-template-columns: 6% 1fr 6%;
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        padding-top: 2%;
        height: fit-content;
    }

    #rns-cards-main {
        display: none;
    }

    #rns-main-mobile {
        height: 100%;
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: column;
        gap: 2%;
    }

    #rns-card-row {
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        gap: 22px;
        height: 20rem;
        overflow-x: auto;

    }

    #rns-card {
        width: 100%;
        height: 5rem;
        display: flex;
        flex-direction: row;
        background-color: #f5f5f5;
    }

    #rns-image {
        height: 100%;
        min-height: 100%;
        min-width: 30%;
        max-width: 30%;
        margin: 0%;
        border-radius: 5px;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;

        width: 30%;
        object-fit: cover;
    }

    #btnrnsCatagory {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        font-size: 10px;
        padding: 1px;
        position: absolute;
        margin: 0%;
        top: 0%;
        left: 0%;
    }

    #rns-card-body {
        margin-top: 2%;
        padding: 3%;
        height: 17%;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    @media (max-width: 450px) {

        #rns-Viewmore {
            text-align: end;
            text-decoration: underline;
            margin-top: 8%;
            font-size: 12px;
        }

        #rns-card-body {
            margin-top: 2%;
            padding: 3%;
            height: 17%;
            font-size: 12px;
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
            line-height: 1.2;
        }
    }

}

/* ---------------------------------- rns weather card ------------------------------------ */

#rns-weather-card {
    height: 20rem;
    background: black;
    overflow: hidden;
    border: none;
}

#rns-weather-image {
    object-fit: cover;
    opacity: 0.4;
    height: 20rem;
}

#weather-main-text {
    color: #ffffff;
}

#weather-logo {
    position: relative;
    margin-top: 2%;
    margin-left: 1%;
}

#wcard-body {
    display: grid;
    grid-template-columns: 1% 6fr 1fr;
    margin-top: 6%;
}

#wcard-left-body {
    display: grid;
    grid-column: 2/3;
}

#wcard-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 33%;
    width: 100%;
    background-color: #ffffff49;
    display: flex;
    flex-direction: row;
    gap: 2.5%;
    justify-content: center;
    padding-top: 4%;
}

/* ----------------------------------------- Popular News --------------------------------------- */

#rns-PNews-main {
    border: 1px solid #c0c5d0;
    border-radius: 1%;
    height: 38rem;
}

#rns-PNews-card {
    width: 96%;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    background: #ffffff;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: row;
}

#rns-PNews-image {
    object-fit: cover;
    height: 7rem;
    width: 30%;
    border-radius: 3%;
}

#PNews-card-body {
    /* padding: 5%; */
    padding-left: 2%;
}

#rns-PNews-card-text {
    margin: 4%;
}

/* -------------------------------------------- tags ----------------------------------------- */

#rns-tags-main {
    border: 1px solid #c0c5d0;
    border-radius: 5px;
    height: 15rem;
}

#tags-nav {
    border: 2px solid #01afeb;
    border-radius: 5px;
    color: #ffffff;
    background-color: #01afeb;
    /* padding-top: 2%; */
    justify-content: center;
    height: 3rem;
}

#tags-tag {
    margin-top: 2%;
}

#btnTags {
    margin-left: 3%;
    margin-top: 2%;
}

/* -----------------------------video player sectin ---------------------------------- */

#vps-main {
    height: fit-content;
    padding-bottom: 2%;
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    color: #ffffff;
    background-color: #1a2e51;
}

#vps-head-main {
    display: grid;
    padding-top: 1%;
    grid-template-columns: 6% 4fr 1fr 6%;
    margin-bottom: 1%;
}

#vps-main-logo {
    display: grid;
    grid-column: 2/3;
    margin-top: 2%;
    position: relative;
    font-size: 22px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}


#vps-Viewmore {
    font-family: 'Nunito', sans-serif;
    text-decoration: underline;
    letter-spacing: 1px;
    text-align: end;
    color: #ffffff;
    margin-top: 8%;
}

#vps-body {
    display: grid;
    grid-template-columns: 4.5% 1fr 1fr 4.5%;
    gap: 1.5%;
}

#vps-body-left {
    display: grid;
    grid-column: 2/3;
}

#vps-main-card {
    height: 46rem;
    width: 100%;
    background-color: #00000000;
    border: none;
}

#vps-card-overlay {
    height: 65%;
}

#vps-main-image {
    box-shadow: 3px 3px 15px 15px #0f1f40;
    object-fit: cover;
    opacity: 0.8;
    border-radius: 10px;
    height: 65%;
    width: 100%;
}

#vps-card-body {
    height: 35%;
    width: 100%;
}

#vps-main-title {
    color: #ffffff;
    margin-left: 1%;
    position: relative;
    font-size: 30px;
    top: 30%;
}

#vps-main-date {
    color: #ffffff;
    position: absolute;
    bottom: 3%;
    font-size: 1.2rem;
    margin-left: 1%;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

#vps-body-right {
    display: grid;
    grid-column: 3/4;
    display: flex;
    flex-direction: column;
    gap: 4%;
}

#vps-image-cards {
    background-color: #000000;
    border-radius: 10px;
    height: 22rem;
    border: none;
}

#vps-secondry-images {
    box-shadow: 3px 3px 15px 15px #0f1f40;
    opacity: 0.8;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

#vps-card-title {
    position: absolute;
    bottom: 1%;
    left: 0;
    margin: 1%;
    padding: 5%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 23px;
    line-height: 1.2;
}

@media (max-width: 1200px) {

    #vps-main-card {
        height: 40rem;
        width: 100%;
        background-color: #00000000;
        border: none;
    }

    #vps-main-title {
        color: #ffffff;
        margin-left: 1%;
        position: relative;
        font-size: 24px;
        top: 20%;
    }

    #vps-image-cards {
        background-color: #000000;
        border-radius: 10px;
        height: 19rem;
        border: none;
    }

    #vps-card-title {
        position: absolute;
        bottom: 1%;
        left: 0;
        margin: 1%;
        padding: 5%;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 22px;
        line-height: 1.2;
    }

}

@media (max-width: 940px) {
    #vps-main-card {
        height: 36rem;
        width: 100%;
        background-color: #00000000;
        border: none;
    }

    #vps-main-title {
        color: #ffffff;
        margin-left: 1%;
        position: relative;
        font-size: 20px;
        top: 20%;
    }

    #vps-image-cards {
        background-color: #000000;
        border-radius: 10px;
        height: 17rem;
        border: none;
    }

    #vps-card-title {
        position: absolute;
        bottom: 1%;
        left: 0;
        margin: 1%;
        padding: 5%;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        line-height: 1.2;
    }
}

@media (max-width: 800px) {
    #vps-main-card {
        height: 32rem;
        width: 100%;
        background-color: #00000000;
        border: none;
    }

    #vps-main-title {
        color: #ffffff;
        margin-left: 1%;
        position: relative;
        font-size: 18px;
        top: 15%;
    }

    #vps-image-cards {
        background-color: #000000;
        border-radius: 10px;
        height: 15rem;
        border: none;
    }

    #vps-card-title {
        position: absolute;
        bottom: 1%;
        left: 0;
        margin: 1%;
        padding: 5%;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        line-height: 1.2;
    }
}

@media (max-width: 650px) {

    #vps-main-logo {
        display: grid;
        grid-column: 2/3;
        margin-top: 2%;
        position: relative;
        font-size: 18px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    #vps-Viewmore {
        font-size: 14px;
        text-align: end;
        text-decoration: underline;
        color: #ffffff;
        margin-top: 8%;
    }

    #vps-body {
        display: grid;
        grid-template-columns: 4.5% 1fr 4.5%;
        gap: 0%;
    }

    #vps-body-left {
        display: grid;
        grid-column: 2/3;
    }

    #vps-body-right {
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: row;
        gap: 2%;
        margin-bottom: 2%;
    }

    #vps-main-card {
        height: 26rem;
        width: 100%;
        background-color: #00000000;
        border: none;
    }

    #vps-main-title {
        color: #ffffff;
        margin-left: 1%;
        position: relative;
        font-size: 18px;
        top: 10%;
    }

    #vps-image-cards {
        background-color: #000000;
        border-radius: 10px;
        height: 10rem;
        min-width: 50%;
        border: none;
    }

    #vps-card-title {
        position: absolute;
        bottom: 1%;
        left: 0;
        margin: 1%;
        padding: 5%;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        line-height: 1.2;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 4.1em;
    }
}

@media (max-width: 400px) {

    #vps-main-logo {
        display: grid;
        grid-column: 2/3;
        margin-top: 2%;
        position: relative;
        font-size: 16px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    #vps-Viewmore {
        font-size: 12px;
        text-align: end;
        text-decoration: underline;
        color: #ffffff;
        margin-top: 8%;
    }

    #vps-card-overlay {
        height: 65%;
    }

    #vps-main-image {
        box-shadow: 3px 3px 15px 15px #0f1f4000;
        object-fit: cover;
        opacity: 0.8;
        border-radius: 10px;
        height: 60%;
        width: 100%;
    }

    #vps-card-body {
        height: 40%;
        width: 100%;
    }

    #vps-image-cards {
        background-color: #000000;
        border-radius: 10px;
        height: 10rem;
        min-width: 50%;
        border: none;
    }

    #vps-card-title {
        position: absolute;
        bottom: 1%;
        left: 0;
        margin: 1%;
        padding: 5%;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        line-height: 1.2;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.9em;
    }
}

/* -----------------------------modal css -------------------------------------------------- */
#vps-modal-btnclose {
    background-color: #00000000;
    border: none;
    position: absolute;
    background: #0f1f40;
    box-shadow: 1px 1px 5px 0px #000000;
    top: -15%;
    right: -10%;
}

#vps-modal-btnclose:hover {
    box-shadow: 0 0 1px 8px #0f1f405e, 0 0 1px 16px #0f1f4023;
}

#btnClose-logo {
    color: #ffffff;
    /* opacity: 0.8; */

    border-radius: 10px;
}

#vps-modal-header {
    border: none;
}

/* .vps-modal-title{
/* background-color: #9eb633; */
/* } */

#vps-modal-body {
    padding: 0;
}

#vps-btnVideo {
    float: inline-end;
    color: #ffffff;
    background: #fff;
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    right: 3%;
}

#vps-btnVideo:hover {
    /* transform: rotate(360deg); */
    box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
    0 0 1px 16px rgba(255, 255, 255, 0.1);
}

#vps-btnVideo-logo {
    margin-left: 10%;
}

#vps-videoPlayer {
    padding: 3% 3% 1% 3%;
    /* width: 94%; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

#vps-multi-video {
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin-top: 1%;
    height: 12rem;
    justify-content: center;
}

#multi-video-btn {
    display: flex;
    flex-direction: row;

    width: 25%;
    height: 10rem;
    border: none;
}

#vns-btn-image {
    height: auto;
    opacity: 0.3;
    width: 33%;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 0.5%;
}

#vns-btn-text {
    margin-top: 7%;
    bottom: 7%;
}

#vns-btn-date {
    position: absolute;
    margin-top: 0.5%;
    margin-left: 1%;
    font-size: small;
}

/* -----------------------------health news section------------------------- */

#hns-head {
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
}

#hns-head-main {
    display: grid;
    grid-template-columns: 6% 4fr 1fr 6%;

}

#hns-main-logo {
    display: grid;
    grid-column: 2/3;
    margin-top: 3%;
    /* max-width: 2000px; */
    position: relative;
    font-size: 22px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

#hns-Viewmore {
    text-align: end;
    font-family: 'Nunito', sans-serif;
    text-decoration: underline;
    letter-spacing: 1px;
    color: #2b5db3;
    margin-top: 13%;
}

#hns-main {
    margin-top: 2%;
    font-family: "Zilla Slab", serif;
    color: #1a2e51;
    height: fit-content;
    padding-bottom: 2%;
    display: grid;
    grid-template-columns: 6% 1fr 1% 1fr 1% 1fr 6%;
}

#hns-main-mobile {
    display: none;
}

#hns-main-card {
    display: grid;
    grid-column: 2/3;
    width: 100%;
    height: 30rem;
    overflow: hidden;
    border: none;
}

#hns-main-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#hns-center-col {
    display: grid;
    grid-column: 4/5;
    display: flex;
    flex-direction: column;
    gap: 6%;
}

#hns-right-col {
    display: grid;
    grid-column: 6/7;
    display: flex;
    flex-direction: column;
    gap: 6%;
}

#hns-main-body {
    padding: 0% !important;
    margin: 1%;
    /* margin-top: 65%; */
    color: #ffffff;
}

#hns-main-text {
    position: absolute;
    bottom: 1%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 2%;
    padding: 3%;
}

#hns-card {
    width: 100%;
    height: 6rem;
    border: none;
    display: flex;
    flex-direction: row;
}

#hns-card-image {
    object-fit: cover;
    height: 100%;
    width: 28%;
    min-width: 28%;
    border-radius: 5px;
}

#hns-card-body {
    /* padding: 5%; */
    width: 70%;
    padding-left: 2%;
}

#hns-card-text {
    font-size: 18px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 4%;
}

#btnhnsCatagory {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin: 2%;
}

#btnhnsCatagorysub {
    color: #ffffff;
    background: #01afeb;
    width: fit-content;
    margin-top: 4%;
    margin-left: 4%;
}

@media (max-width: 1420px) {

    #hns-main-card {
        display: grid;
        grid-column: 2/3;
        width: 100%;
        height: 27.5rem;
        overflow: hidden;
        border: none;
    }

    #hns-center-col {
        display: grid;
        grid-column: 4/5;
        display: flex;
        flex-direction: column;
        gap: 4%;
    }

    #hns-right-col {
        display: grid;
        grid-column: 6/7;
        display: flex;
        flex-direction: column;
        gap: 4%;
    }

    #hns-card-text {
        font-size: 16px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 2%;
    }

    #btnhnsCatagorysub {
        color: #ffffff;
        background: #01afeb;
        width: fit-content;
        margin-top: 2%;
        margin-left: 2%;
    }

}

@media (max-width: 900px) {

    #hns-main {
        margin-top: 2%;
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        height: fit-content;
        padding-bottom: 2%;
        display: grid;
        grid-template-columns: 6% 1fr 2% 1fr 6%;
    }

    #hns-center-col {
        display: grid;
        grid-column: 4/5;
        display: flex;
        flex-direction: column;
    }

    #hns-right-col {
        display: none;
    }

}

@media (max-width: 700px) {
    #hns-main-card {
        display: grid;
        grid-column: 2/3;
        width: 100%;
        height: 25.6rem;
        overflow: hidden;
        border: none;
    }

    #hns-center-col {
        display: grid;
        grid-column: 4/5;
        display: flex;
        flex-direction: column;
        gap: 2%;
    }
}

@media (max-width: 600px) {

    #hns-head-main {
        display: grid;
        grid-template-columns: 6% 3fr 1fr 6%;

    }

    #hns-main-logo {
        display: grid;
        grid-column: 2/3;
        margin-top: 3%;
        /* max-width: 2000px; */
        position: relative;
        font-size: 18px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }


    #hns-Viewmore {
        font-size: 14px;
        text-align: end;
        text-decoration: underline;
        margin-top: 13%;
    }

    #hns-main {
        margin-top: 2%;
        font-family: "Zilla Slab", serif;
        color: #1a2e51;
        height: fit-content;
        padding-bottom: 2%;
        display: grid;
        grid-template-columns: 6% 1fr 6%;
        gap: 0.5rem;
    }

    #hns-main-card {
        display: grid;
        grid-column: 2/3;
        width: 100%;
        height: 15rem;
        overflow: hidden;
        border: none;
    }

    #hns-center-col {
        display: grid;
        grid-column: 2/3;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }
}