@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#B_NV-main{
    display: grid;
    grid-template-columns: 7% 1fr 7%;
    /* width: 100%; */
    height: fit-content;
    font-family: 'Zilla Slab', serif;
    color: #1A2E51;
}

#B_NV-page{
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2%;
    margin-top: 5rem;
}

#btnB_NVCatagory{
    color: #ffffff; 
    background:#EE2934;
    width: fit-content;
  }

#B_NV-right-section{
    display: flex;
    flex-direction: column;
    gap: 1%;
}

#B_NV-body{
    width: 100%;
max-width: 100%;
}

#B_NV-title{
    margin-top: 1%;
}


/* ------------------------------------------------------------------- */

#B_NV-Header{
    display: flex;
    justify-content: space-between;
    height: 3rem;
    background-color: #ffffff ;
    color: #1A2E51;
    border-top: 1px solid #C0C5D0;
    border-bottom: 1px solid #C0C5D0;
    font-family: 'Zilla Slab', serif;
    gap: 32%;
}

#B_NV-left-head{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    
}

#B_NV-right-head{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

#B_NV-Share-Label{
    margin-top: 4%;
}




#btncalendar{
    
    color: #ffffff; 
    background:#EE2934;
    justify-content: center;
}

#head-lables{
    font-size: 16px;
    margin-top: 6%;
}

#head-logos{
    margin-bottom: 3px;
    /* margin-right: 7px; */
}

#line-head{
    height: 35px;
    width: 0.5px;
    color: #909090;
    background-color: #909090;
}

#B_NV-image{
    margin-top: 1%;
    margin-bottom: 1%;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

#B_NV-functions{
    border: 1px solid #C0C5D0;
    border-radius: 10px;
    background-color: #f5f5f5;
    height: 6rem;
    display: grid;
    grid-template-columns: 3% 3fr 3%;
}

#B_NV-functions-left{
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
}

#B_NV-function-pair{
    margin-left: 10%;
    margin-top: 1.7rem;
    justify-content: center;
}

#B_NV-function{
    border: none;
    padding: 0%;
    color: #1A2E51;
    text-decoration: none;
    margin: 1%;

}

#B_NV-function-text{
    font-size: small;
}

#B_NV-font-lable{
    margin-top: 0.5rem;
}

#B_NV-FontRange{
    color: #1A2E51;
    width: 100%;
}

#B_NV-FontRange-labels{
    /* margin-right: 10%; */
    font-size: small;

}

#B_NV-functions-right{
    display: none;
    flex-direction: row;
    justify-content: right;
}

#B_NV-description{
    margin-top: 3%;
    /* width: 20rem; */
    width: 100%;
    /* max-width: 100%; */
}

#B_NV-description img{
    max-width: 100% !important;
    height: auto;
}

#B_NV-description video{    
    max-width: 100%;
    max-height: 40rem;
    height: auto;
}

@media (max-width: 1200px) {
    #B_NV-main{
        display: grid;
        grid-template-columns: 7% 1fr 7%;
        /* width: 100%; */
        height: fit-content;
        font-family: 'Zilla Slab', serif;
        color: #1A2E51;
    }
    
    #B_NV-page{
        grid-column: 2/3;
        display: grid;
        grid-template-columns:1fr;
        gap: 2%;
        margin-top: 5rem;
    }
    
    #btnB_NVCatagory{
        color: #ffffff; 
        background:#EE2934;
        width: fit-content;
      }
    
    #B_NV-right-section{

        display: none;
        flex-direction: column;
        gap: 1%;
    }
}

@media (max-width: 800px) {

    #B_NV-Header{
    
        height: 3rem;
        background-color: #ffffff ;
        color: #1A2E51;
        border-top: 1px solid #C0C5D0;
        border-bottom: 1px solid #C0C5D0;
        font-family: 'Zilla Slab', serif;
        gap: 20%;
    }

    #head-lables{
        font-size: 14px;
        margin-top: 6%;
    }
}

@media (max-width: 660px) {

    #B_NV-Header{
    
        height: 3rem;
        background-color: #ffffff ;
        color: #1A2E51;
        border-top: 1px solid #C0C5D0;
        border-bottom: 1px solid #C0C5D0;
        font-family: 'Zilla Slab', serif;
        gap: 10%;
    }
}

/* @media (max-width: 400px) {

        #B_NV-Header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 4rem;
            background-color: #ffffff ;
            color: #1A2E51;
            border-top: 1px solid #C0C5D0;
            border-bottom: 1px solid #C0C5D0;
            font-family: 'Zilla Slab', serif;
            gap: 0%;
        }
} */

@media (max-width: 425px) {
    #B_NV-FontRange-labels{
        /* margin-right: 10%; */
        font-size: 10px;
    
    }
}
