@media screen and (min-width: 700px) {
  #ModalWrapper11 {
    min-width: 820px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    background-color: #ee2934;
    border-radius: 20px;
  }
  .border-radius-2 div {
    border-radius: 20px;
  }
  .Background {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(185, 151, 151, 0.115); */
    filter: blur(5px);
    /* background-color: transparent; */
  }
  .logo-img-overlay {
    position: absolute;
    top: 3%;
    left: 3%;
  }
  .logo-text2 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }
  #logo2 {
    width: 25%;
    height: auto;
  }
  #ModalImg2 {
    width: 100%;
    height: 39rem;
    border-radius: 21px 0 0 21px;
    opacity: 0.5;
    object-fit: cover;
  }

  .my-2 {
    margin-top: -2.5rem !important;
  }
  .welcom-back2 {
    margin-left: 5%;
    margin-bottom: 15%;
  }
  .ModelHeader {
    position: relative;
    top: 10px;
    left: 20px;
    height: 32px;
    z-index: 10;
  }
  .form {
    position: relative;
    font-size: medium;
    left: 20px;
    height: 32px;
    padding: 20px;
    z-index: 10;
  }

  #submitbutton {
    color: #ffffff;
    background: #1b2d51;
  }
  #submitbutton:hover {
    background-color: #fff;
    color: #1b2d51;
    border: #1b2d51 solid 3px;
    font-weight: bold;
  }
  .error-msg {
    font-size: smaller;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0.5rem;
  }
  #modal-content2 {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
}

@media screen and (max-width: 700px) {
  #login_img2 {
    display: none;
  }
  #ModalWrapper {
    background-color: #f5f5f5;
  }
  .welcom-back2 {
    margin-left: 5%;
    margin-bottom: 15%;
  }
  #submitbutton {
    color: #ffffff;
    background: #1b2d51;
  }
}
