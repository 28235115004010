@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");



@media  (min-width: 1100px) {
  #main_contact_us {
    margin-left: 12%;
    margin-right: 12%;
    font-family: "Zilla Slab", serif;
  }
  #contact_us {
    display: grid;
    grid-template-columns: 200fr 200fr 200fr;
    gap: 5%;
    padding-top: 2%;
  }
  .main-card {
    /* height: 25%; */
    width: 100%;
  }
  .text1 {
    padding-top: 2%;
  }
  .text-mute {
    padding-top: 3%;
    font-size: 18px;
    color: darkgrey;
  }
  .form-value {
    display: grid;
    grid-template-columns: 100fr 100fr;
    /* min-height: 35rem; */
    height: 33rem;
  }
  #Send_Message {
    color: #ffffff;
    background: #ee2934;
  }
  #Send_Message:hover {
    background-color: #1a2e51;
  }
  #map{ 
    width: 50rem;
    height: 30rem;
  }
  #contact_us {
    display: grid;
    grid-template-columns: 200fr 200fr 200fr;
    gap: 5%;
    padding-top: 2%;
    margin: 5%;
  }
  #card-social{
    display: flex;
    flex-direction: row;
    gap: 2%;
  }
  #it{
    color: #ffffff;
    background-color: #EE2934;
    width: 20%;
  }
   #recaptcha{
    display: flex;
    flex-direction: row;
    gap: 5%;
  } 
  .recap1{
    padding-top: 4%;
    position: relative;
    right: 4%
  }
}

@media  (max-width: 1500px) { 
  #contact-form {
  margin: 6%;
  }
  .recap1{
    
    position: relative;
    right: 4%
  }
  #recaptcha{
    /* display: flex;
    flex-direction: row; */
    gap: 10%;
  } 
  .map{
    background-color: #f5f5f5;
    height: fit-content;
  }
  #map{ 
    width: 90%;
    height: 15rem;
    margin: 5%;
    border-radius: 5%;
  }
  #Send_Message {
    color: #ffffff;
    background: #ee2934;
   /* margin: 1rem 3rem 0rem 3rem; */
    width:fit-content;
    font-size: 12px;
  }
  #Send_Message:hover {
    background-color: #1a2e51;
  }
  .text1 {
    padding-top: 2%;
    margin: 5%;
  }
  #card-contact{
    width: 90%;
    /* height: 50%; */
    margin-left: 5%;
  }
  #card-social{
    display: flex;
    flex-direction: row;
    gap: 5%;
  }
  #it{
    color: #ffffff;
    background-color: #EE2934;
    width: fit-content;
  }
  .text-mute {
    padding-top: 3%;
    font-size: 18px;
    color: darkgrey;
  }
  .py-3{
    padding-top: 1.5%;
  }
}
@media  (max-width: 700px) { 
  .recap1{
    margin: 0.5rem 1rem 0rem 0.5rem;
    position: relative;
    right: 12%
  }
  #recaptcha{
    gap: 10%;
  } 
  .map{
    background-color: #f5f5f5;
    height: fit-content;
  }
  #map{ 
    width: 90%;
    height: 15rem;
    margin: 5%;
    border-radius: 5%;
  }
  #Send_Message {
    color: #ffffff;
    background: #ee2934;
   /* margin: 1rem 3rem 0rem 3rem; */
    width:100%;
    font-size: 12px;
  }
  #Send_Message:hover {
    background-color: #1a2e51;
  }
  .text1 {
    padding-top: 2%;
    margin: 5%;
  }
  #card-contact{
    width: 90%;
    /* height: 50%; */
    margin-left: 5%;
  }
  #card-social{
    display: flex;
    flex-direction: row;
    gap: 5%;
  }
  #it{
    color: #ffffff;
    background-color: #EE2934;
    width: fit-content;
  }
  .text-mute {
    padding-top: 3%;
    font-size: 18px;
    color: darkgrey;
  }
  .py-3{
    padding-top: 1.5%;
  }
}