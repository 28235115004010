/* desktop mode */
/* Newsbar css */

.Newsbar {
  font-family: "Zilla Slab", serif;
  width: 100%;
  height: 5rem;
  display: grid;
  grid-template-columns: 6% 4fr 6fr;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

/* News logo */
.News-logo {
  display: grid;
  grid-column: 2/3;
  /* justify-content: start; */
  /* align-items: center; */
  /* position: absolute; */
}

#NewsLogo {
  height: 3rem;
  width: auto;
  margin: 1rem;
}

/* manu-links and buttons */
.Manu-links {
  grid-column: 3/4;
}

.hamburger-manu {
  display: none;
}

.mobile-manu {
  display: none;
}

.Manu-links ul {
  height: 5rem;
  display: flex;
  justify-content: space-around;
  /* grid-template-columns: 4fr ,repeat(2,1fr); */
  align-items: center;
}

.Manu-links ul li {
  list-style-type: none;
  align-items: right;
}
.Manu-links ul a {
  text-decoration: none;
}

#nb-popover-body {
  width: 18rem;
  height: fit-content;
  padding: 0%;
  border: none;
  /* border: 1px solid #909090; */
}

#Nav-Offcanvas {
  font-family: "Zilla Slab", serif;
}

.nav-item {
  list-style-type: none;
}
.navbar-container {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
}

/* .nav-item:hover {
    
} */
li b a {
  padding: 9px;
}
li b a:hover {
  background-color: #01afeb;
  border-radius: 6px;
}
/*
#NavHover :hover {
  height: 5rem;
  padding-top: 1.7rem;
  background-color: #01afeb;
}
*/
.link-color:hover {
  color: #ffffff !important;
}

#nav-links {
  color: #1a2e51;
  position: relative;
  text-decoration: none;
}

/* mobile mode */

@media (max-width: 1400px) {
  #nav-links {
    font-size: 14px;
  }

  #catNav-links {
    color: #1a2e51;
    font-size: 12px;
    text-decoration: none;
    margin-right: 2rem;
  }
}

@media (max-width: 1200px) {
  .Newsbar {
    height: 5rem;
    grid-template-columns: 6% 1fr 2fr 1fr 6%;
  }

  .News-logo {
    display: grid;
    grid-column: 2/3;
    /* justify-content: start; */
    /* align-items: center; */
    /* position: absolute; */
  }

  #NewsLogo {
    height: 2.5rem;
    width: auto;
    margin: 1rem;
    margin-top: 1.3rem;
  }

  .mobile-manu {
    display: none;
  }

  .mobile-manu2 {
    display: contents;
  }

  .Manu-links {
    display: none;
  }

  #cn-main {
    display: none;
  }
  .hamburger-manu {
    display: contents;
    grid-column: 4/5;
    display: flex;
    justify-content: right;
    /* align-items: center; */
    margin-top: 1.5rem;
    height: fit-content;
  }
  #nav-links {
    font-size: 22px;
  }

  #catNav-links {
    font-size: 22px;
    margin-top: 1%;
  }

  #Nav-btns {
    margin-top: 5%;
  }
}

#Nav-btns {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}
/* @media only screen and (max-width: 1200px) and (min-width: 1001px) {
  .nav-item {
   
    list-style-type: none;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 801px) {
  .nav-item {
  
    list-style-type: none;
  }
}
@media only screen and (max-width: 800px) and (min-width: 601px) {
  .nav-item {
    list-style-type: none;
  }
}
@media only screen and (max-width: 600px) and (min-width: 401px) {
  .nav-item {
    margin-top: 6%;
    list-style-type: none;
  }
}
@media only screen and (max-width: 400px) and (min-width: 150px) {
  .nav-item {
    margin-top: 10%;
    list-style-type: none;
  } }*/

/* ---------------------- Navbar css -------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");
/* News main nave */

#navbarSupportedContent {
  font-family: "Zilla Slab", serif;
}

#NewsNav {
  width: 100%;
  height: 6rem;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;     */
}

#nav-link {
  color: #1a2e51;
  text-decoration: none;
}

/* nav buttons */

#btnSignIn {
  color: #ffffff;
  background: #01afeb;
  border: none;
}

#btnSignIn:hover {
  background: #1a2e51;
}

#btnBookmark {
  text-decoration: none;
  color: #ffffff;
  border: none;
}

#btnBookmark:hover {
  background: #ee2934;
}

#btnLogout {
  color: #ffffff;
  /* background:#1A2E51; */
  border: none;
}

#btnLogout:hover {
  background: #ee2934;
}
.btnSignIn {
  color: #ffffff;
  background: #ee2934;
  border: none;
}

.btnSignIn:hover {
  background: #1a2e51;
}

#btnNotification {
  color: #ee2934;
  background: #f5f5f5;
  border: none;
}

#btnNotification:hover {
  color: #ffffff;
  background: #1a2e51;
}

#btnSerch {
  color: #ee2934;
  background: #f5f5f5;
  margin-right: 110px;
  border: none;
}

#btnSerch:hover {
  color: #ffffff;
  background: #1a2e51;
}

#serchModal {
  position: absolute;
  background: #ffffff;
  border: 1px solid #c0c5d0;
  padding: 0%;
  /* top: 90%;
    right: 5%; */
}

/* #inputSerchForm{
    /* border-color: #EE2934; */
/* } */

#btnSerchForm {
  color: #ee2934;
  background: #f5f5f5;
}

/* button logo */

#btnLogo {
  margin-bottom: 2px;
  margin-right: 7px;
}

/* ---------------------- respons Navbar css -------------------------- */

/* ---------------------- Header css -------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");

#Header {
  height: 3rem;
  background-color: #1a2e51;
  color: #ffffff;
  font-family: "Zilla Slab", serif;
  gap: 0%;
}

.left-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#header-city-section {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  text-align: "justify";
  line-height: 1%;
  margin-top: 5%;
}

#header-city-section-day {
  font-size: medium;
}

.right-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#head-trend-news {
  margin-top: 20px;
  background: #101d38;
}

/* header buttons */

#btncalendar {
  color: #ffffff;
  background: #ee2934;
  justify-content: center;
}

/* logos */
#logoCalendar {
  margin-bottom: 4px;
  margin-right: 7px;
}

#line-head {
  height: 35px;
  width: 0.5px;
  color: #909090;
  background-color: #909090;
}
#btnHead-Socials {
  padding: 0%;
  color: #ffffff;
  background: #ee2934;
}

#btnHead-Socials svg {
  margin-bottom: 5%;
}

#drop-head-lang {
  color: #ffffff;
  outline-color: #ffffff;
}

/* ---------------------------------- cat nav --------------------------- */

#cn-main {
  font-family: "Zilla Slab", serif;
  border-top: 0.1px solid #00000063;
  border-bottom: 0.1px solid #00000063;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#catNav-links {
  color: #1a2e51;
  text-decoration: none;
  margin-right: 2rem;
}
/*-------------------------maxmedia nav bar header----------------------------------*/

/* @media screen and (min-width: 1201px) {
  .navheadno {
    display: none !important;
  }
}
@media screen and (max-width: 1201px) {
  .padbotmor {
    padding-bottom: 40px !important;
  }
  .navheadno {
    top: 27px !important;
    padding: 0 45px !important;
  }
}
@media screen and (max-width: 920px) {
  .padbotmor {
    padding-bottom: 40px !important;
  }
  .navheadno {
    top: 33px !important;
    padding: 0 45px !important;
  }
}
@media screen and (max-width: 720px) {
  .padbotmor {
    padding-bottom: 40px !important;
  }
  .navheadno {
    top: 45px !important;
    padding: 0 45px !important;
  }
}
@media screen and (max-width: 520px) {
  .padbotmor {
    padding-bottom: 50px !important;
  }
  .navheadno {
    top: 45px !important;
    padding: 0 45px !important;
  }
}

@media screen and (max-width: 375px) {
  .navheadno {
    top: 50px !important;
    padding: 0 25px !important;
  }
  .padbotmor {
    padding-bottom: 45px !important;
  }
}
@media screen and (max-width: 375px) and (min-width: 165px) {
  .navheadno {
    top: 50px !important;
    padding: 0 25px !important;
  }

  .padbotmor {
    padding-bottom: 45px !important;
  }
}

.headneavb::-webkit-scrollbar {
  display: none;
} */
@media screen and (min-width: 820px) {
  .navheadnoWrapper {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .navheadnoWrapper {
    margin: 0 28px;
  }
}
@media screen and (min-width: 480px) {
  .navheadnoWrapper {
  }
  .catewrp,
  .butfnsz {
    font-size: 17px;
  }
}
@media screen and (min-width: 375px) {
  .navheadnoWrapper {
  }
  .catewrp,
  .butfnsz {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 374px) and (min-width: 320px) {
  .navheadnoWrapper {
  }
  .catewrp,
  .butfnsz {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 280px) {
  .catewrp,
  .butfnsz {
    font-size: 12px !important;
  }
}
