@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');

#ts-main{
    font-family: 'Zilla Slab', serif;
    display: grid;
    grid-template-columns: 6% 1fr 6%;
}

#ts-content{
    display: grid;
    grid-column: 2/3;
}

#ts-card{
    width: 100%;
    height: 27rem;
    border: none;
    margin-top: 1%;
}

#ts-card:hover{
    border: 1px solid #C0C5D0;
}

#ts-card-image{
    width: 100%;
    height: 60%;
    object-fit: cover;
}

#ts-card-body{
    /* margin-top: 20%; */
    padding: 1%;
    height: 40%;
}

#ts-btnCatagory{
    margin-top: 3%;
    color: #ffffff; 
    background:#EE2934;
    width: fit-content;
}

#ts-card-title{
    margin-top: 2%;
}

#btncvRead{
    color:#EE2934; 
    background:#d1d1d1;
    border: none;
}

#ts-card-date{
    font-size: large;
}

#ts-logoCalendar{
    margin-bottom: 0.6%;
    margin-right: 1%;
}