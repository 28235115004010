@media screen and (min-width: 700px) {
  .otp-container {
    align-items: center;
    justify-content: center;
  }
  .otp-container input[type="password"] {
    min-width: 50px;
    min-height: 50px;
    text-align: center;
    margin-right: 20px;
  }
  .ModalWrapper55 {
    min-width: 680px;
    height: 43.5rem;
    display: flex;
    flex-direction: row;
    background-color: #ee2934;
    border-radius: 20px;
  }
  .Background {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(185, 151, 151, 0.115); */
    filter: blur(5px);
  }

  .logo-img-overlay {
    position: absolute;
    top: 3%;
    left: 3%;
  }
  .border-radius-2 div {
    border-radius: 20px;
  }
  #logo5 {
    width: 25%;
    height: auto;
  }
  .ModalImg5 {
    width: 100%;
    height: 36rem;
    border-radius: 21px 0 0 21px;
    opacity: 0.5;
    object-fit: cover;
  }
  .logo-text5 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }
  .my-2 {
    margin-top: -2.5rem !important;
  }
  .h {
    margin-top: 6.5rem !important;
    margin-bottom: 3rem !important;
  }

  .ModelHeader {
    position: relative;
    top: 10px;
    left: 20px;
    height: 32px;
    z-index: 10;
  }
  .form {
    position: relative;
    font-size: medium;
    left: 20px;
    height: 32px;
    padding: 20px;
    z-index: 10;
  }

  #submitbutton {
    color: #ffffff;
    background: #1b2d51;
  }
  #submitbutton:hover {
    background-color: #fff;
    color: #1b2d51;
    border: #1b2d51 solid 3px;
    font-weight: bold;
  }
  #resendbutton {
    color: #1b2d51;
    /* background: #1b2d51; */
    width: fit-content;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 40%;
  }
  #resendbutton:hover {
    background-color: #fff;
    color: #ee2934;
    border: #ee2934 solid 1px;
  }
  .error-msg {
    font-size: smaller;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0.5rem;
  }
  .otp-field {
    margin: 11px;
    height: 50px;
    width: 50px;
    border: #000;
    border-radius: 5px;
    text-align: center;
    font-family: arimo;
    font-size: 1.2rem;
    background: #b1dde8;
  }
  .userInput {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
}
@media screen and (max-width: 700px) {
  .otp-container {
    align-items: center;
    justify-content: center;
  }
  .otp-container input[type="password"] {
    min-width: 25px;
    min-height: 25px;
    text-align: center;
    margin-right: 20px;
  }
  #login_img5 {
    display: none;
  }
  .modal-content {
    border-radius: 20px;
  }
  .PhoneInput {
    display: flex;
    align-items: center;
    margin-top: 17%;
  }
  #resendbutton {
    color: #1b2d51;
    /* background: #1b2d51; */
    width: fit-content;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 25%;
  }
  #resendbutton:hover {
    background-color: #fff;
    color: #ee2934;
    border: #ee2934 solid 1px;
  }
}
