@media screen and (min-width: 700px) {
  .ModalWrapper44 {
    min-width: 680px;
    height: 36rem;
    display: flex;
    flex-direction: row;
    background-color: #ee2934;
    border-radius: 20px;
  }
  .phoneInput{
    margin-top: 20%;
  }
  .Background {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(185, 151, 151, 0.115); */
    filter: blur(10px);
  }

  .logo-img-overlay {
    position: absolute;
    top: 3%;
    left: 3%;
  }
  .welcom-back4{
    margin: 5%;
    
  }
  .border-radius-2 div {
    border-radius: 20px;
  }
  #logo4 {
    width: 25%;
    height: auto;
  }
  .logo-text4 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }
  .ModalImg4 {
    width: 100%;
    height: 36rem;
    border-radius: 21px 0 0 21px;
    opacity: 0.5;
    object-fit: cover;
  }
  #modal-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
  .my-2 {
    margin-top: -2.5rem !important;
  }
  /* .h {
    margin-top: 6.5rem !important;
    margin-bottom: 3rem !important;
  } */
  .ModelHeader {
    position: relative;
    top: 10px;
    left: 20px;
    height: 32px;
    z-index: 10;
  }
  .form {
    position: relative;
    font-size: medium;
    left: 20px;
    height: 32px;
    padding: 20px;
    z-index: 10;
  }

  #submitbutton {
    color: #ffffff;
    background: #1b2d51;
  }
  #submitbutton:hover{
    background-color: #fff;
    color: #1b2d51;
    border: #1b2d51 solid 3px;
    font-weight: bold;

  }
  .error-msg {
    font-size: smaller;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 700px) {
  #login_img4 {
    display: none;
  }
  #modal-content {
    border-radius: 20px;
  }
  .welcom-back4{
    margin-top:5%;
    margin-bottom: 5%;
    margin-left: 5%;
    
  }
}
